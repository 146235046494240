import { EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Empty, Popconfirm, Row, Skeleton, Space, Table, Tag, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";

import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Link, useNavigate } from "react-router-dom";
import { ERROR, getLocalStorageItems, isValidArray, sendNotification } from "utils/utilities";

import { t } from "i18next";
import { toJS } from "mobx";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

export const DefaultDashboard = () => {
  const { doctorDashboardStore } = useMainStore();
  const [selectedYear, setSelectedYear] = useState(moment());
  const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
  const navigate = useNavigate();

  useEffect(() => {
    const { getDashboardStatistics, setIsLoadingDashboardData, getDashboardAppointmentList } = doctorDashboardStore;

    setIsLoadingDashboardData(true);
    getDashboardStatistics(doctorId, selectedYear.format("YYYY"))
      .then(() => getDashboardAppointmentList({ doctorId, limit: 5 }))
      .catch(({ message }) => {
        sendNotification({ type: ERROR, message: message });
      })
      .finally(() => setIsLoadingDashboardData());
  }, [selectedYear]);

  const rowClassName = ({ time, is_completed, is_canceled }, index) => {
    const [startTime, endTime] = time.split("-");
    const utcStartTime = moment.utc(startTime, "hh:mm A").local().format("hh:mm A");
    const utcEndTime = moment.utc(endTime, "hh:mm A").local().format("hh:mm A");
    const currentTime = moment();
    const activeTag = moment(utcStartTime, "hh:mm A").isAfter(currentTime) || moment(utcEndTime, "hh:mm A").isAfter(currentTime);
    const inBetweenTime = currentTime.isBetween(moment(utcStartTime, "hh:mm A"), moment(utcEndTime, "hh:mm A"));
    if (activeTag && !is_completed && !is_canceled) {
      return ""; // Apply 'highlight-row' class for rows where age is less than 35
    }
    return "disable_row"; // No class name for other rows
  };

  const columns = [
    {
      title: t("UID"),
      dataIndex: "time",
      key: "unique_id",
      render: (_, { unique_id }, ind) => <p className="mb-0 text-gray text-capitalize">{unique_id ? unique_id : "_"}</p>,
    },
    {
      title: t("Appointment Time"),
      dataIndex: "time",
      key: "time",
      render: (_, { time, is_completed, is_canceled }, ind) => {
        const [startTime, endTime] = time.split("-");
        const utcStartTime = moment.utc(startTime, "hh:mm A").format("hh:mm A");
        const utcEndTime = moment.utc(endTime, "hh:mm A").format("hh:mm A");

        const currentTime = moment();
        const activeTag = moment(utcStartTime, "hh:mm A").isAfter(currentTime) || moment(utcEndTime, "hh:mm A").isAfter(currentTime);
        const inBetweenTime = currentTime.isBetween(moment(utcStartTime, "hh:mm A"), moment(utcEndTime, "hh:mm A"));

        return (
          <Tag color={activeTag && !is_completed && !is_canceled ? "processing" : "default"} className={`mb-0 text-capitalize `}>
            {utcStartTime + " - " + utcEndTime}
          </Tag>
        );
      },
    },
    {
      title: t("Patient"),
      dataIndex: "name",
      key: "name",
      render: (_, { patientProfile }, ind) => (
        <div className="d-flex align-items-center">
          {/* <img src={patientProfile.profile_pic} className="table_img" width={50} height={50} alt="" /> */}
          <h5 className="mb-0 ml-2">{patientProfile.name}</h5>
        </div>
      ),
    },
    {
      title: t("Gender"),
      dataIndex: "gender",
      key: "gender",
      render: (_, { patientProfile }, ind) => <p className="mb-0 text-gray text-capitalize">{patientProfile.gender}</p>,
    },

    {
      title: t("Appointment Type"),
      dataIndex: "appointmentType",
      key: "appointmentType",
      render: (_, { appointmentType }, ind) => <p className="mb-0 text-gray text-capitalize">{appointmentType}</p>,
    },
    {
      title: t("Appointment Mode"),
      dataIndex: "appointmentMode",
      key: "appointmentMode",
      render: (_, { appointmentMode }, ind) => <p className="mb-0 text-gray text-capitalize">{appointmentMode == "online" ? "Virtual" : "Physical"}</p>,
    },

    {
      title: t("Appointment Status"),
      key: "is_completed",
      dataIndex: "is_completed",
      render: (_, { is_completed, is_canceled }) => (
        <>
          <Tag color={`${is_completed ? "#6FCE3F" : is_canceled ? "#FF6B72" : "#FBBF45"}`} key={is_completed}>
            {is_completed ? t("COMPLETED") : is_canceled ? t("CANCELED") : t("PENDING")}
          </Tag>
        </>
      ),
    },
    {
      title: <p className="text-center text-dark mb-0 font-weight-bold">{t("Actions")}</p>,
      key: "action",
      render: (_, { patient_id, appointment_id, patientProfile, appointmentMode, is_completed, is_canceled }) => (
        <Space size="middle" className="d-flex justify-content-center">
          <Tooltip placement="top" title={t("View Appointment")}>
            <Button className="tabel_icon" type="primary" ghost>
              <Link
                to={`/doctor/view-appointment`}
                state={{
                  patientId: patient_id,
                  appointmentId: appointment_id,
                  name: patientProfile.name,
                  type: "upcoming",
                }}
              >
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
          {/* {appointmentMode === "online" && ( */}
          <Tooltip placement="top" title={appointmentMode === "offline" ? "" : is_completed ? "" : is_canceled ? t("Appointment Cancel") : t("Join Appointment")}>
            <Popconfirm
              title={t("Are you sure you want to join this consultation?")}
              description="Open Popconfirm with Promise"
              placement="topRight"
              onConfirm={() => {
                navigate("/video-consultation", {
                  state: {
                    userType: 0,
                    appointmentId: appointment_id,
                    patientId: patient_id,
                    doctorId,
                  },
                });
              }}
              disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}
              okText={t("Yes")}
              cancelText={t("No")}
              onOpenChange={() => console.log("open change")}
            >
              <Button className="tabel_icon" type="primary" disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}>
                <VideoCameraOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
          {/* )} */}
        </Space>
      ),
    },
  ];

  return (
    <Observer>
      {() => {
        const { dashboardData, dashboardAppointmentList, isLoadingDashboardData } = doctorDashboardStore;
        const [{ appointmentsCount, totalPatientsServed, totalRevenue, currency }] = dashboardData;
        const countAppointmentArray = appointmentsCount.map((item) => +item.count);
        const countTotalRevenue = totalRevenue.map((item) => +item.total);
        const maxAppointment = Math.max(...countAppointmentArray);
        const maxRevenueAmount = Math.max(...countTotalRevenue);

        const appointmentOptions = {
          chart: {
            type: "line",
            height: 350,

            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                reset: false,
                pan: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },

          xaxis: {
            categories: toJS(appointmentsCount)?.map(({ month }) => month),
          },
          // title: {
          //     text: `Appointments & Patients : ${selectedYear.format("YYYY")}`,
          //     align: "left",
          //     margin: 10,
          //     offsetX: 0,
          //     offsetY: 0,
          //     floating: false,
          //     style: {
          //         fontSize: "16px",
          //     },
          // },
          colors: ["#FFC542", "#5B95F8", "#29D895"],
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          yaxis: {
            title: {
              text: t("appointment & patients"),
            },
            labels: {
              formatter: function (val) {
                return Math.ceil(val);
              },
            },
            min: 0,
            max: maxAppointment !== 0 ? maxAppointment : 10,
            tickAmount: 5,
          },
        };

        const appointmentSeries = [
          {
            name: t("Appointments"),
            data: toJS(appointmentsCount).map(({ count }) => count),
          },
          {
            name: t("Total Patients"),
            data: toJS(totalPatientsServed).map(({ patientsCount }) => patientsCount),
          },
        ];

        const revenueChartOptions = {
          chart: {
            height: 350,
            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                reset: false,
                pan: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          colors: ["#29D895"],
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${currency?.currency_symbol ? currency.currency_symbol : "$"}${val}`;
              },
            },
          },
          yaxis: {
            title: {
              text: t(`Revenue ${currency?.currency_name ? `(${currency.currency_name})` : "USD"}`),
            },
            labels: {
              formatter: function (val) {
                return `${currency?.currency_symbol ? currency.currency_symbol : "$"}${Math.ceil(val)}`;
              },
            },
            min: 0,
            max: maxRevenueAmount !== 0 ? maxRevenueAmount : 100,
            tickAmount: 5,
          },
        };

        const revenueChartSeries = [
          {
            name: "Total Revenue",
            data: toJS(totalRevenue).map(({ total }) => +total.toFixed(2)),
          },
        ];

        revenueChartOptions.xaxis = { categories: toJS(totalRevenue).map(({ month }) => month) };

        return (
          <Fragment>
            <Row justify="space-between" className="mb-3">
              <Col>
                <h2>{t("Dashboard")}</h2>
              </Col>
              <Col>
                <Row justify="end">
                  <DatePicker
                    allowClear={false}
                    picker="year"
                    style={{ border: "1px solid rgb(194, 194, 194)" }}
                    onChange={(e) => setSelectedYear(e)}
                    defaultValue={selectedYear}
                    disabledDate={(current) => {
                      return current && current.year() > moment().year();
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Card style={{ minHeight: "350px" }}>
                      <Skeleton title={{ width: "100%" }} loading={isLoadingDashboardData} paragraph={{ rows: 10 }} />
                      {!isLoadingDashboardData && (
                        <>
                          <div className="d-flex justify-content-between ">
                            <h4>{t("Appointments & Patients")}</h4>
                            <h4>{selectedYear.format("YYYY")}</h4>
                          </div>
                          <ReactApexChart options={appointmentOptions} series={appointmentSeries} type="line" height={350} />
                        </>
                      )}
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                      <Skeleton title={{ width: "100%" }} loading={isLoadingDashboardData} paragraph={{ rows: 10 }} />
                      {!isLoadingDashboardData && (
                        <>
                          <div className="d-flex justify-content-between ">
                            <h4>{t("Revenue")}</h4>
                            <h4>{selectedYear.format("YYYY")}</h4>
                          </div>
                          <ReactApexChart options={revenueChartOptions} series={revenueChartSeries} type="line" height={350} />
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Card style={{ background: "#fff", padding: "0px" }} className="d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h2 className="font-size-lg">{t("Today's Appointments")}</h2>
                        <Link to={"/doctor/appointments?type=upcoming"}>{t("See All")}</Link>
                      </div>
                      {isValidArray(dashboardAppointmentList) ? (
                        <Table
                          columns={columns}
                          pagination={false}
                          dataSource={dashboardAppointmentList.map((item, ind) => ({
                            ...item,
                            key: ind,
                          }))}
                          rowClassName={rowClassName}
                        />
                      ) : (
                        <Empty description={t("No appointments today")} />
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default DefaultDashboard;
