import { MailOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import PhoneNumberInput from "components/util-components/Phone/PhoneNumberInput";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ERROR, sendNotification } from "utils/utilities";

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { loginStore } = useMainStore();
  const { sendResetPasswordLink } = loginStore;

  const [forgotPasswordForm] = Form.useForm();

  const [loading, setLoading] = React.useState(false);

  const handleFormSubmit = async () => {
    try {
      const formData = await forgotPasswordForm.validateFields();

      setLoading(true);

      const payloadEmail = {
        email: formData.email,
        signWith: "email",
      };

      const payloadMobile = {
        mobile: "+" + formData.mobile,
        signWith: "mobile",
      };

      const { success, message } = await sendResetPasswordLink(formData.email ? payloadEmail : payloadMobile);

      if (success) {
        forgotPasswordForm.resetFields();
        loginStore.setForgotPasswordModal(false);
        sendNotification({
          type: "success",
          message: formData.mobile ? t("SMS sent successfully.") : t("Email sent successfully."),
        });
        setLoading(false);

        return;
      }

      sendNotification({ message, type: ERROR });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.hasOwnProperty("errorFields")) return;
    }
  };

  return (
    <>
      <Observer>
        {() => {
          const { forgotPasswordModal, setForgotPasswordModal } = loginStore;
          return (
            <Fragment>
              <Modal
                maskClosable={false}
                centered
                title={t("Forgot Password")}
                open={forgotPasswordModal.show}
                onOk={handleFormSubmit}
                onCancel={() => {
                  forgotPasswordForm.resetFields();
                  setForgotPasswordModal(false);
                }}
                confirmLoading={loading}
                width={400}
              >
                <Form form={forgotPasswordForm} layout="vertical" name="forgot-password-form" validateTrigger="onSubmit">
                  {forgotPasswordModal.isDoctor ? (
                    <>
                      <Form.Item
                        className="m-0"
                        name="email"
                        label={t("Email")}
                        rules={[
                          {
                            required: true,
                            message: t("Please input your email"),
                          },
                          {
                            type: "email",
                            message: t("Please enter a valid email!"),
                          },
                        ]}
                      >
                        <Input prefix={<MailOutlined className="text-primary" />} placeholder={t("Enter Email")} />
                      </Form.Item>
                      <p className="text-gray mb-0 text-[12px] mt-2">{t("You will receive an email to reset your password")}.</p>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name="mobile"
                        label={t("Mobile")}
                        rules={[
                          {
                            required: true,
                            message: t("Please enter your mobile number."),
                          },
                        ]}
                      >
                        <PhoneNumberInput />
                      </Form.Item>
                      <p className="text-gray mb-0 text-[12px]" style={{ marginTop: "-15px" }}>
                        {t("You will receive a sms to reset your password")}.
                      </p>
                    </>
                  )}
                </Form>
              </Modal>
            </Fragment>
          );
        }}
      </Observer>
    </>
  );
};

export default ForgotPasswordForm;
