import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import Loading from "components/shared-components/Loading";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect, useState } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";

const DoctorFeesStructure = () => {
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [isCurrencyEditable, setIsCurrencyEditable] = useState(false);
  console.log("🚀 ~ DoctorFeesStructure ~ currencySymbol:", currencySymbol);

  const { doctorProfileStore } = useMainStore();
  const isFirstLogin = useFirstLogin();

  const amountRule = (rule, value, callback) => {
    if (value && value < 0) {
      callback(t("Please enter a valid amount"));
    } else {
      callback();
    }
  };

  const formRules = {
    offline_new_case_fees: {
      required: true,
      message: t("New case fees is required!"),
    },
    offline_ongoing_case_fees: {
      required: true,
      message: t("Ongoing case fees is required!"),
    },
    online_new_case_fees: {
      required: true,
      message: t("New case fees is required!"),
    },
    online_ongoing_case_fees: {
      required: true,
      message: t("Ongoing case fees is required!"),
    },
    accepted_payment_mode: {
      required: true,
      message: t("Accepted payment mode is required!"),
    },
  };

  useEffect(() => {
    const { getDoctorFeesDetailsById, setIsLoadingDoctorFees, getCurrenciesForDoctor } = doctorProfileStore;
    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

    getCurrenciesForDoctor();
    setIsLoadingDoctorFees(true);
    getDoctorFeesDetailsById(doctorId)
      .then(() => {})
      .finally(() => setIsLoadingDoctorFees());
  }, []);

  const handleCurrencyChange = (value) => {
    setIsCurrencyEditable(true);
    const { doctorCurrencyList } = doctorProfileStore;
    const currency = doctorCurrencyList.find((currency) => currency._id === value);
    setCurrencySymbol(currency?.currency_symbol);
  };

  const handleFeesInfoForm = async (feesInfo) => {
    const { addOrUpdateFeesStructure, setIsUpdatingFeesStructure } = doctorProfileStore;

    const { user_id: doctorId, first_login } = getLocalStorageItems({
      getAll: true,
      key: ["user_id", "step", "first_login"],
    });
    setIsUpdatingFeesStructure(true);
    await addOrUpdateFeesStructure({ doctorId, feesInfo }).finally(() => {
      setIsUpdatingFeesStructure();
      setIsCurrencyEditable(false);
      isFirstLogin(first_login, 2);
    });
  };

  return (
    <Observer>
      {() => {
        const { doctorFeesStructure, isLoadingDoctorFees, isUpdatingFeesStructure, doctorCurrencyList } = doctorProfileStore;
        const currencyList = doctorCurrencyList.map(({ _id, currency, country_name }) => ({
          label: `${country_name} - ${currency}`,
          value: _id,
        }));

        const [{ offline_new_case_fees, offline_ongoing_case_fees, currency_id, online_new_case_fees, online_ongoing_case_fees, accepted_payment_mode }] = doctorFeesStructure;

        if (currency_id && !isCurrencyEditable) {
          const currency = doctorCurrencyList.find((currency) => currency._id === currency_id);
          setCurrencySymbol(currency?.currency_symbol);
        }

        if (isLoadingDoctorFees) {
          return <Loading />;
        }

        return (
          <Fragment>
            <h2 className="mb-4">{t("Fees Structure")}</h2>

            <Form
              layout="vertical"
              name="fees-structure"
              onFinish={handleFeesInfoForm}
              initialValues={{
                offline_new_case_fees,
                currency_id,
                offline_ongoing_case_fees,
                online_new_case_fees,
                online_ongoing_case_fees,
                accepted_payment_mode,
              }}
            >
              <Row>
                <Col span={24}>
                  <Row gutter={ROW_GUTTER}>
                    {/* FEES SECTION */}
                    <>
                      <Col span={24}>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <h4 className="mb-0 text-gray" level={5}>
                              {t("Currency")}
                            </h4>
                          </Col>
                          <Col xxl={6} xl={6} xs={24}>
                            <Form.Item label={t("Select Currency")} name="currency_id" rules={[formRules.offline_new_case_fees, { validator: amountRule }]}>
                              <Select
                                options={currencyList}
                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                showSearch
                                placeholder="Select Doctor"
                                optionFilterProp="label"
                                onChange={handleCurrencyChange}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <h4 className="mb-0 text-gray" level={5}>
                              {t("Physical Appointment charge")}
                            </h4>
                          </Col>
                          <Col xxl={6} xl={6} xs={24}>
                            <Form.Item label={t("New Case Fees")} name="offline_new_case_fees" rules={[formRules.offline_new_case_fees, { validator: amountRule }]}>
                              <Input className="no-spinners" type="number" prefix={currencySymbol} />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} xl={6} xs={24}>
                            <Form.Item label={t("Ongoing Case Fees")} name="offline_ongoing_case_fees" rules={[formRules.offline_ongoing_case_fees, { validator: amountRule }]}>
                              <Input className="no-spinners" type="number" prefix={currencySymbol} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <h4 className="mb-0 text-gray" level={5}>
                              {t("Virtual Appointment charge")}
                            </h4>
                          </Col>
                          <Col xxl={6} xl={6} xs={24}>
                            <Form.Item label={t("New Case Fees")} name="online_new_case_fees" rules={[formRules.online_new_case_fees, { validator: amountRule }]}>
                              <Input className="no-spinners" type="number" prefix={currencySymbol} />
                            </Form.Item>
                          </Col>

                          <Col xxl={6} xl={6} xs={24}>
                            <Form.Item label={t("Ongoing Case Fees")} name="online_ongoing_case_fees" rules={[formRules.online_ongoing_case_fees, { validator: amountRule }]}>
                              <Input className="no-spinners" type="number" prefix={currencySymbol} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  </Row>
                  <Row gutter={ROW_GUTTER}>
                    <>
                      <Col xxl={6} xl={6} xs={24}>
                        <Form.Item label={t("Payment Acceptance Mode")} name="accepted_payment_mode" rules={[formRules.accepted_payment_mode]}>
                          <Checkbox.Group
                            options={[
                              { label: t("e-Payment"), value: "online" },
                              { label: t("Cash"), value: "offline" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  </Row>
                  <Button loading={isUpdatingFeesStructure} type="primary" htmlType="submit">
                    {t("Save Change")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default DoctorFeesStructure;
