import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Table, Tag } from "antd";
import { DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";

const PatientTransactions = () => {
  const { patientStore } = useMainStore();
  const navigate = useNavigate();

  const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

  useEffect(() => {
    const { getPatientTransectionsDetails, setIsLoadingForPatientTransectionsDetails } = patientStore;
    setIsLoadingForPatientTransectionsDetails(true);
    getPatientTransectionsDetails(patientId)
      .then(({ success, data }) => {
        if (success) setIsLoadingForPatientTransectionsDetails(false);
      })
      .finally(() => [setIsLoadingForPatientTransectionsDetails(false)]);
  }, []);

  const columns = [
    {
      title: t("Payment Date"),
      dataIndex: "paidOn",
      key: "paidOn",
      render: (_, { paidOn }, ind) => moment.utc(paidOn).format(DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM),
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount, currency_symbol }, ind) => (
        <p className="text-capitalize mb-0">
          {currency_symbol}
          {amount}
        </p>
      ),
    },
    {
      title: t("Payment Method"),
      dataIndex: "paidUsing",
      key: "paidUsing",
      render: (_, { paidUsing }, ind) => <p className="text-capitalize mb-0">{paidUsing}</p>,
    },
    {
      title: t("Payment Status"),
      dataIndex: "status",
      key: "status",
      render: (_, { status }, ind) => (
        <Tag color={`${status == "paid" || status == "COMPLETED" ? "#6FCE3F" : status == "pending" ? "#FBBF45" : "#FF6D6D"}`} key={ind}>
          {status == "paid" || status == t("COMPLETED") ? t("PAID") : status == "pending" ? t("PENDING") : t("FAILED")}
        </Tag>
      ),
    },
    {
      title: <Row justify="center">{t("View Appointment")}</Row>,
      dataIndex: "appointment_id",
      key: "appointment_id",
      render: (_, { appointment_id }, ind) => (
        <Row justify="center">
          <Button className="tabel_icon" type="primary" ghost>
            <Link
              to="/patient/view-appointment"
              state={{
                appointment_id,
                patient_id: patientId,
                type: "past",
              }}
            >
              <EyeOutlined />
            </Link>
          </Button>
        </Row>
      ),
    },
  ];
  return (
    <Observer>
      {() => {
        const { isLoadingForPatientTransectionsDetails, patientTransectionsDetails } = patientStore;
        return (
          <Spin spinning={isLoadingForPatientTransectionsDetails && !isValidArray(patientTransectionsDetails)}>
            <Row>
              <h2> {t("Transactions History")}</h2>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={patientTransectionsDetails.map((item, ind) => ({
                    ...item,
                    key: ind,
                  }))}
                />
              </Col>
            </Row>
          </Spin>
        );
      }}
    </Observer>
  );
};

export default PatientTransactions;
