import { Card, Col, Row, Table } from "antd";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DD_MMM_YYYY } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect, useState } from "react";
import { getLocalStorageItems, isValidArray } from "utils/utilities";

const DoctorPayouts = () => {
  const [symbol, setSymbol] = useState("");

  const { doctorStore } = useMainStore();
  const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
  useEffect(() => {
    const { getPayoutListForDoctor, setIsLoadingForDoctorPayoutList, getDoctorAvailableBalance } = doctorStore;

    setIsLoadingForDoctorPayoutList(true);
    getDoctorAvailableBalance(doctorId)
      .then(async () => {
        return await getPayoutListForDoctor(doctorId);
      })
      .then(({ success }) => {
        if (success) {
          setIsLoadingForDoctorPayoutList();
        }
      })
      .finally(() => setIsLoadingForDoctorPayoutList());
  }, []);

  const tableColumns = [
    {
      title: t("Payment #"),
      dataIndex: "payout_unique_id",
      key: "payout_unique_id",
    },
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (_, { date }) => <p className="mb-0 text-dark text-capitalize">{moment(date, "DD-MM-YYYY").format(DATE_FORMAT_DD_MMM_YYYY)}</p>,
    },
    {
      title: t("Payment Method"),
      dataIndex: "method",
      key: "method",
      render: (_, { method }) => <p className={`mb-0 text-dark ${method == "cash" ? "text-capitalize" : ""} `}>{method}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => (
        <p className="mb-0 text-dark">
          {symbol}
          {amount}
        </p>
      ),
    },
  ];
  return (
    <Observer>
      {() => {
        const { isLoadingForDoctorPayoutList, doctorPayoutList, doctorAvailableBalance } = doctorStore;
        if (isLoadingForDoctorPayoutList && !isValidArray(doctorAvailableBalance) && !isValidArray(doctorAvailableBalance)) {
          return <Loading />;
        }
        const [{ balance = 0, currency_symbol } = {}] = doctorAvailableBalance;
        setSymbol(currency_symbol);
        return (
          <>
            <Row>
              <Col>
                <h2>{t("Payouts")}</h2>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={8}>
                <Card title={t("Available Balance")}>
                  <h1 className="font-size-xxl">
                    {currency_symbol}
                    {balance}
                  </h1>
                </Card>
              </Col>
              <Col></Col>
            </Row>
            <Row className="mt-2">
              <Col span={24}>
                <Table
                  columns={tableColumns}
                  dataSource={doctorPayoutList.map((item, ind) => ({
                    ...item,
                    key: ind,
                  }))}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Observer>
  );
};

export default DoctorPayouts;
