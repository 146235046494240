import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Radio, Row, Select, Space, Table, Upload, message } from "antd";
import Flex from "components/shared-components/Flex";
import Loading from "components/shared-components/Loading";
import PhoneNumberInput from "components/util-components/Phone/PhoneNumberInput";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { t } from "i18next";
import { Observer } from "mobx-react-lite";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems, isValidArray, isValidObject } from "utils/utilities";

const EditDoctorProfile = () => {
  const { globalStore, doctorProfileStore } = useMainStore();
  const isFirstLogin = useFirstLogin();
  const [documentForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadDoc, setUploadDoc] = useState({});
  const uploadRef = useRef();
  const [documents, setDocuments] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const { getAllSpecialities, getAllDegrees, getExperienceKeyValue, getGenderKeyValue } = globalStore;
    const { setIsLoadingProfileData, getDoctorProfileData } = doctorProfileStore;

    const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });

    getGenderKeyValue();
    getExperienceKeyValue();

    getAllSpecialities()
      .then(() => getAllDegrees())
      .then(() => getDoctorProfileData(user_id))
      .finally(() => setIsLoadingProfileData(false));
  }, [documents]);

  const formRules = {
    name: {
      required: true,
      message: t("Name is required."),
    },
    gender: {
      required: true,
      message: t("Gender is required."),
    },
    dob: {
      required: true,
      message: t("Date of birth is required."),
    },
    experience: {
      required: true,
      message: t("Experience is required."),
    },
    mobile_no: {
      required: true,
      message: t("Mobile no. is required."),
    },
    speciality: {
      required: true,
      message: t("Speciality is required."),
    },
    degree: {
      required: true,
      message: t("Degree is required."),
    },
    about: {
      required: true,
      message: t("About is required."),
    },
  };
  const uploadDocRules = {
    document_name: {
      required: true,
      message: t("Document Name is required."),
    },
    document_file: {
      required: true,
      message: t("Document File is required."),
    },
  };

  const columns = [
    {
      title: t("Document Name"),
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("View Document"),
      dataIndex: "url",
      key: "url",
      render: (_, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          <EyeOutlined />
        </a>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "approve",
      key: "approve",
      render: (text) => <span>{text === 3 ? t("Rejected") : text === 1 ? t("Approved") : t("Pending")}</span>,
    },
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm title="Are you sure delete this document?" onConfirm={() => deleteDocument(record.id)} onCancel={() => console.log("Cancel delete")} okText="Yes" cancelText="No">
            <a href="#">{t("Delete")}</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleUploadDocument = async () => {
    setUploadLoading(true); // Start the loader
    try {
      let uploadFormData = new FormData();
      uploadFormData.append("name", uploadDoc?.document_name);
      uploadFormData.append("file", uploadDoc?.document_file);

      const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
      const { data } = await doctorProfileStore.getUrlForUploadedDocument(uploadFormData, doctorId);

      if (data) {
        setDocuments((prevDocuments) => [...prevDocuments, data]);
        setIsModalOpen(false); // Close the modal if needed
        documentForm.resetFields(); // Reset the form fields
      }
    } catch (error) {
      message.error(t("Failed to upload document"));
    } finally {
      setUploadLoading(false); // Stop the loader
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    documentForm.resetFields();
    setUploadDoc({});
  };

  const onFinish = async (values) => {
    const { updateDoctorProfile, setIsUpdatingProfileData, getDoctorProfileData } = doctorProfileStore;
    const { setHeaderNavProfileDetail } = globalStore;
    const { user_id: doctorId, first_login } = getLocalStorageItems({
      getAll: true,
      key: ["user_id", "step", "first_login"],
    });

    try {
      setIsUpdatingProfileData(true);
      const { name, degree, dob, speciality, mobile_no, ...rest } = values;
      const newBody = {
        ...rest,
        name: name.includes("Dr.") || name.includes("dr.") ? name : "Dr." + " " + name,
        speciality: speciality.map((spe) => spe).join(","),
        degree: degree.map((deg) => deg).join(","),
        dob: moment(dob).format(DATE_FORMAT_DD_MM_YYYY),
        mobile_no: mobile_no.includes("+") ? mobile_no : "+" + mobile_no,
      };

      await updateDoctorProfile({ body: newBody, doctorId })
        .then(() => {
          getDoctorProfileData(doctorId).then(({ success, data }) => {
            if (success && isValidArray(data)) {
              const [{ name, degrees, profile_pic }] = data;
              setHeaderNavProfileDetail({
                userName: name,
                userDegree: degrees,
                userProfile: profile_pic,
              });
              return;
            }
            setHeaderNavProfileDetail();
          });
          setIsUpdatingProfileData();
        })
        .finally(() => {
          setIsUpdatingProfileData();
          isFirstLogin(first_login, 0);
        });
    } catch (error) {
      console.log("onFinish", error.message);
    }
  };

  const deleteDocument = async (docId) => {
    try {
      const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
      const { data } = await doctorProfileStore.deleteDocumentItems(doctorId, docId);
    } catch (error) {
      console.log("onFinish", error.message);
      message.error(error.message);
      return false;
    }
  };
  const fileUploadSettings = {
    accept: "image/*",
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      console.log("🚀 ~ EditDoctorProfile ~ info:", info);
      try {
        setIsImageEdit(true);
        const { updateProfileImage, getUserDetailsById, setHeaderNavProfileDetail } = globalStore;
        const { getDoctorProfileData } = doctorProfileStore;
        const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

        if (info.fileList.length > 0) {
          const [uploadedFile] = info.fileList;
          setFileList(info.fileList);
          const fileName = uploadedFile.name;

          if (isValidObject(uploadedFile)) {
            const formData = new FormData();
            formData.append("file", uploadedFile.originFileObj);

            updateProfileImage({ userId: doctorId, formData }).then(({ success, data }) => {
              if (success) {
                setIsImageEdit(false);
                getUserDetailsById(doctorId);
                getDoctorProfileData(doctorId).then(({ success, data }) => {
                  if (success && isValidArray(data)) {
                    const [{ name, degrees, profile_pic }] = data;
                    setHeaderNavProfileDetail({
                      userName: name,
                      userDegree: degrees,
                      userProfile: profile_pic,
                    });
                    return;
                  }
                  setHeaderNavProfileDetail();
                });
              }
            });
          }
        }
      } catch (error) {}
    },
  };

  return (
    <Observer>
      {() => {
        const { allSpecialities, allDegrees, experienceKeyValue, genderKeyValue, isUpdatingProfileImage } = globalStore;
        const { isLoadingProfileData, doctorProfileData, isUpdatingProfileData } = doctorProfileStore;
        const [{ name, dob, gender, about, mobile_no, experience, specialities, degrees, profile_pic, documents }] = doctorProfileData;

        if (isLoadingProfileData) {
          return <Loading />;
        }

        return (
          <Fragment>
            <>
              <Flex alignItems="center" mobileFlex={false} className="text-center text-md-left">
                <Space>
                  {/* <Avatar size={90} src={profile_pic ? profile_pic : "/img/avatars/thumb-6.jpg"} icon={<UserOutlined />} /> */}
                  {/* <div className="ml-3 mt-md-0 mt-3">
                                        <Upload disabled={isUpdatingProfileImage} {...fileUploadSettings} fileList={[]} maxCount={1}>
                                            <Button loading={isUpdatingProfileImage} type="primary">{isUpdatingProfileImage ? " Updating... " : "Change Profile"}</Button>
                                        </Upload>
                                    </div> */}
                  <div className="profile_img">
                    <Avatar size={90} src={isImageEdit ? fileList : profile_pic ? profile_pic : "/img/avatars/thumb-6.jpg"} icon={<EditOutlined />} />
                    <EditOutlined className="cursor_pointer" onClick={() => uploadRef.current.click()} />
                    <Space size={[16]}>
                      <Upload className="mr-2" disabled={isUpdatingProfileImage} {...fileUploadSettings} fileList={[]} maxCount={1}>
                        <Button ref={uploadRef} className="p-0 d-none" loading={isUpdatingProfileImage} type="primary">
                          {isUpdatingProfileImage ? " Updating... " : ""}
                        </Button>
                      </Upload>
                    </Space>
                  </div>
                </Space>
              </Flex>
              <div className="mt-4">
                <Form
                  name="basicInformation"
                  layout="vertical"
                  initialValues={{
                    name: name,
                    gender,
                    about,
                    mobile_no,
                    experience,
                    dob: dob ? moment(dob, "DD-MM-YYYY") : null,
                    speciality: specialities?.map(({ speciality_id }) => speciality_id),
                    degree: degrees?.map(({ degree_id }) => degree_id),
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Row gutter={ROW_GUTTER}>
                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Name")} name="name" rules={[formRules.name]}>
                            <Input placeholder="John" />
                          </Form.Item>
                        </Col>
                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Date of Birth")} name="dob" rules={[formRules.dob]}>
                            <DatePicker allowClear={false} className="w-100" format={DATE_FORMAT_DD_MM_YYYY} />
                          </Form.Item>
                        </Col>
                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Gender")} name="gender" rules={[formRules.gender]}>
                            <Radio.Group>
                              {genderKeyValue?.map(({ label, value }) => (
                                <Radio key={value} value={value}>
                                  {t(label)}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Experience (Years)")} name="experience" rules={[formRules.experience]}>
                            <InputNumber min={0} max={99} className="w-100" />
                            {/* <Select options={experienceKeyValue} /> */}
                          </Form.Item>
                        </Col>
                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Mobile No.")} name="mobile_no" rules={[formRules.mobile_no]}>
                            {/* <Input className="w-100" placeholder="Mobile" /> */}
                            <PhoneNumberInput />
                          </Form.Item>
                        </Col>

                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Speciality")} name="speciality" rules={[formRules.speciality]}>
                            <Select
                              placeholder="Your specialization"
                              mode="multiple"
                              maxTagCount={1}
                              maxTagPlaceholder={(value) => `+ ${value.length} more ...`}
                              style={{
                                width: "100%",
                              }}
                              options={allSpecialities.map(({ speciality_name, _id }) => ({
                                value: _id,
                                label: speciality_name,
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col xxl={8} xl={12} xs={24}>
                          <Form.Item label={t("Degree")} name="degree" rules={[formRules.degree]}>
                            <Select
                              placeholder={t("Your Degrees")}
                              mode="multiple"
                              style={{
                                width: "100%",
                              }}
                              options={allDegrees.map(({ degree_name, _id }) => ({
                                value: _id,
                                label: degree_name,
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col xxl={16} xl={24} xs={24}>
                          <Form.Item label={t("About")} name="about" rules={[formRules.about]}>
                            <Input.TextArea placeholder={t("Skilled cardiologist with MBBS & MS, 3 years' expertise.")} rows={3} className="w-100" />
                          </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => setIsModalOpen(true)}
                                                            block
                                                        >
                                                            {t("Add Document")}
                                                        </Button>
                                                    </Col> */}

                        {documents?.length > 0 && (
                          <Col span={24}>
                            <Table className="m-3" columns={columns} dataSource={documents} pagination={true} />
                          </Col>
                        )}

                        <Col>
                          <Button loading={isUpdatingProfileData} type="primary" htmlType="submit">
                            {t("Save Change")}
                          </Button>
                        </Col>
                        <Col>
                          <Button type="dashed" onClick={() => setIsModalOpen(true)} block>
                            {t("Add Document")}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <Modal
                  title={t("Upload Document")}
                  visible={isModalOpen}
                  onCancel={handleCloseModal}
                  okText={t("Upload")}
                  cancelText={t("Cancel")}
                  onOk={documentForm.submit}
                  confirmLoading={uploadLoading}
                >
                  <Form form={documentForm} layout="vertical" initialValues={{ document_name: "", document_file: null }} onFinish={handleUploadDocument}>
                    <Row gutter={ROW_GUTTER}>
                      <Col span={24}>
                        <Form.Item label={t("Document Name")} name="document_name" rules={[uploadDocRules.document_name]}>
                          <Input
                            placeholder={t("Enter document name")}
                            onChange={(e) => {
                              setUploadDoc({
                                ...uploadDoc,
                                document_name: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label={t("Document File")} name="document_file" rules={[uploadDocRules.document_file]}>
                          <Upload
                            beforeUpload={(file) => {
                              setUploadDoc({
                                ...uploadDoc,
                                document_file: file,
                              });
                              return false;
                            }}
                            maxCount={1}
                          >
                            <Button>{t("Select File")}</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </div>
            </>
          </Fragment>
        );
      }}
    </Observer>
  );
  // console.log("🚀 ~ EditDoctorProfile ~ profile_pic:", profile_pic);
};

export default EditDoctorProfile;
