import { Button, Card, Checkbox, Col, DatePicker, Form, Row, Select, Switch } from "antd";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import _ from "lodash";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { FaMinusCircle } from "react-icons/fa";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";

const DoctorAvailability = () => {
  const [form] = Form.useForm();

  const isFirstLogin = useFirstLogin();

  const [formInitialValues, setFormInitialValues] = useState({});
  const [isFieldChange, setIsFieldChange] = useState(false);
  const { doctorProfileStore } = useMainStore();

  useEffect(() => {
    const { getDoctorAvailabilityInfo, setIsLoadingAvailabilityData } = doctorProfileStore;
    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

    setIsLoadingAvailabilityData(true);
    getDoctorAvailabilityInfo(doctorId).finally(() => setIsLoadingAvailabilityData());
  }, []);

  useEffect(() => {
    if (!isFieldChange) {
      form.setFieldsValue(formInitialValues);
    }
  }, [formInitialValues]);

  // Handle route changes

  const onFinish = async (value) => {
    value.availabilityData = value.availabilityData.map((item) => {
      return {
        ...item,
        availability: item?.isAvailable ? item.availability : [],
        workingHours: item?.isAvailable
          ? item.workingHours.map((workingHour) => {
              return {
                startTime: moment(workingHour.startTime).toISOString(),
                endTime: moment(workingHour.endTime).toISOString(),
              };
            })
          : [],
      };
    });

    value.slotDuration = Number(value.slotDuration);
    value.bufferTime = typeof value.bufferTime == "string" ? Number(value.bufferTime.split(" ")[0]) : Number(value.bufferTime);

    const { addOrUpdateDoctorAvailabilityInfo, setIsUpdatingDoctorAvailabilityData } = doctorProfileStore;

    const { user_id: doctorId, first_login } = getLocalStorageItems({
      getAll: true,
      key: ["user_id", "step", "first_login"],
    });
    setIsUpdatingDoctorAvailabilityData(true);
    await addOrUpdateDoctorAvailabilityInfo(value, doctorId).finally(() => {
      setIsUpdatingDoctorAvailabilityData();
      isFirstLogin(first_login, 3);
    });
  };

  const handleAvailabilityChange = (e, name, fieldIndex) => {
    const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);

    const index = _.findIndex(doctorAvailabilityData.availabilityData, { day: name });

    if (index !== -1) {
      doctorAvailabilityData.availabilityData[index].isAvailable = e;
      if (e && doctorAvailabilityData.availabilityData[index].workingHours.length === 0) {
        doctorAvailabilityData.availabilityData[index].workingHours = [{}];
      }
    }

    setDoctorAvailabilityData(doctorAvailabilityData);
  };

  const handleSlotDurationChange = (e) => {
    const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);
    const slotValue = e.split(" ")[0];
    doctorAvailabilityData.slotDuration = slotValue;
    setDoctorAvailabilityData(doctorAvailabilityData);
  };

  const handleBufferTimeChange = (e) => {
    const { doctorAvailabilityData, setDoctorAvailabilityData } = toJS(doctorProfileStore);
    const bufferValue = e.split(" ")[0];
    doctorAvailabilityData.bufferTime = Number(bufferValue);
    setDoctorAvailabilityData(doctorAvailabilityData);
  };

  const checkTimeOverlap = (startTime, endTime, allTimes, currentIndex) => {
    if (!startTime || !endTime) return false;

    for (let i = 0; i < allTimes.length; i++) {
      if (i === currentIndex) continue;

      const existingStart = moment(allTimes[i]?.startTime, "HH:mm");
      const existingEnd = moment(allTimes[i]?.endTime, "HH:mm");

      if (!existingStart || !existingEnd) continue;

      // Check if current time range overlaps with existing time range
      if (
        (startTime.isSameOrAfter(existingStart) && startTime.isBefore(existingEnd)) ||
        (endTime.isAfter(existingStart) && endTime.isSameOrBefore(existingEnd)) ||
        (startTime.isSameOrBefore(existingStart) && endTime.isSameOrAfter(existingEnd))
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Observer>
      {() => {
        const { doctorAvailabilityData, isUpdatingDoctorAvailabilityData, isLoadingAvailabilityData } = doctorProfileStore;

        const data = doctorAvailabilityData;
        setFormInitialValues(doctorAvailabilityData);

        if (isLoadingAvailabilityData) {
          return <Loading />;
        }

        return (
          <Fragment>
            <Form
              form={form}
              onFinish={onFinish}
              name="availability_form"
              autoComplete="off"
              onFieldsChange={() => {
                setIsFieldChange(true);
              }}
              // initialValues={data}
              // layout="vertical"
            >
              <Row gutter={[16, 16]} align="middle" justify="space-between">
                <Col span={20}>
                  <Row gutter={[16, 16]}>
                    <Col span={11} xxl={6} xl={8}>
                      <Form.Item name="slotDuration" label={t("Slot Duration")} rules={[{ required: true, message: "Slot  duration is required." }]} labelCol={{ span: 24 }}>
                        <Select
                          placeholder={t("Select Slot Duration")}
                          options={[
                            { value: "10 Minutes", label: `10 ${t("Minutes")}` },
                            { value: "15 Minutes", label: `15 ${t("Minutes")}` },
                            { value: "20 Minutes", label: `20 ${t("Minutes")}` },
                            { value: "25 Minutes", label: `25 ${t("Minutes")}` },
                            { value: "30 Minutes", label: `30 ${t("Minutes")}` },
                          ]}
                          defaultValue={doctorAvailabilityData.slotDuration}
                          onChange={handleSlotDurationChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} xxl={6} xl={8}>
                      <Form.Item name="bufferTime" label={t("Buffer Time")} rules={[{ required: true, message: "Buffer time is required." }]} labelCol={{ span: 24 }}>
                        <Select
                          placeholder={t("Select break between two slots")}
                          options={[
                            { value: "02 Minutes", label: `02 ${t("Minutes")}` },
                            { value: "05 Minutes", label: `05 ${t("Minutes")}` },
                            { value: "10 Minutes", label: `10 ${t("Minutes")}` },
                            { value: "15 Minutes", label: `15 ${t("Minutes")}` },
                            { value: "20 Minutes", label: `20 ${t("Minutes")}` },
                          ]}
                          defaultValue={doctorAvailabilityData.bufferTime}
                          onChange={handleBufferTimeChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button loading={isUpdatingDoctorAvailabilityData} type="primary" htmlType="submit">
                    {t("Save")}
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 1]} className="availability-row">
                <Form.List name="availabilityData" label="Availability">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Col xxl={8} xl={12}>
                          <Card key={key}>
                            <Row>
                              <Col span={21}>
                                <Form.Item name={[name, "day"]}>
                                  <p className="m-0 font-size-lg">{t(data?.availabilityData[key]?.day)}</p>
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <Form.Item name={[name, "isAvailable"]}>
                                  <Switch defaultChecked={data?.availabilityData[key]?.isAvailable} onChange={(e) => handleAvailabilityChange(e, data?.availabilityData[key]?.day, key)} />
                                </Form.Item>
                              </Col>
                              {data?.availabilityData[key]?.isAvailable && (
                                <>
                                  <Col span={24}>
                                    <Form.Item
                                      name={[name, "availability"]}
                                      label={t("Availability")}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("Availability is required"),
                                        },
                                      ]}
                                    >
                                      <Checkbox.Group
                                        // onChange={(e) => handleOnlineChange(e, day)}
                                        options={[
                                          { label: t("Virtual"), value: "online" },
                                          { label: t("Physical"), value: "offline" },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={24} className="ml-2 mt-2">
                                    <h5>{t("Working Hours")} :</h5>
                                  </Col>
                                  <Form.List name={[name, "workingHours"]} label="Working Time">
                                    {(fields, { add, remove }) => (
                                      <>
                                        {fields.map(({ key: index, name: fieldName, ...restField }) => (
                                          <Row key={index} gutter={[16, 0]}>
                                            <Col span={10}>
                                              <Form.Item
                                                name={[fieldName, "startTime"]}
                                                label={t("From")}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t("Start time is required"),
                                                  },
                                                  ({ getFieldValue }) => ({
                                                    validator: async (_, value) => {
                                                      if (!value) return;

                                                      const startTimeValue = getFieldValue(["availabilityData", name, "workingHours", fieldName, "startTime"]);
                                                      const endTimeValue = getFieldValue(["availabilityData", name, "workingHours", fieldName, "endTime"]);

                                                      const startTime = startTimeValue ? startTimeValue.format("HH:mm") : value.format("HH:mm");

                                                      const endTime = endTimeValue ? endTimeValue.format("HH:mm") : value.format("HH:mm");

                                                      const onlyTimeForStartTime = moment(startTime, "HH:mm");
                                                      const onlyTimeForEndTime = moment(endTime, "HH:mm");

                                                      if (onlyTimeForEndTime && onlyTimeForStartTime.isAfter(onlyTimeForEndTime)) {
                                                        throw new Error(t("Start time must be before end time"));
                                                      }

                                                      const allTimes = getFieldValue(["availabilityData", name, "workingHours"])?.map((time) => ({
                                                        startTime: moment(time?.startTime).format("HH:mm"),
                                                        endTime: moment(time?.endTime).format("HH:mm"),
                                                      }));

                                                      if (checkTimeOverlap(onlyTimeForStartTime, onlyTimeForEndTime, allTimes, fieldName)) {
                                                        throw new Error(t("Time ranges cannot overlap"));
                                                      }
                                                    },
                                                  }),
                                                ]}
                                              >
                                                <DatePicker
                                                  showTime={{
                                                    format: "HH:mm",
                                                    use12Hours: false,
                                                    minuteStep: 15,
                                                  }}
                                                  picker="time"
                                                  placeholder={t("Start Time")}
                                                  allowClear={false}
                                                  size="small"
                                                  className="w-100"
                                                  format="HH:mm"
                                                  onChange={(value, value1) => {
                                                    const dateString = moment().format("YYYY-MM-DD");
                                                    const dateTimeString = `${dateString}T${value1}:00.000Z`;
                                                    const actualDateTime = moment.utc(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSSZ");

                                                    form.setFieldValue(["availabilityData", name, "workingHours", fieldName, "startTime"], actualDateTime);
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={9}>
                                              <Form.Item
                                                name={[fieldName, "endTime"]}
                                                label={t("To")}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t("End time is required"),
                                                  },
                                                  ({ getFieldValue }) => ({
                                                    validator: async (_, value) => {
                                                      if (!value) return;

                                                      const startTimeValue = getFieldValue(["availabilityData", name, "workingHours", fieldName, "startTime"]);
                                                      const endTimeValue = getFieldValue(["availabilityData", name, "workingHours", fieldName, "endTime"]);

                                                      const startTime = startTimeValue ? startTimeValue.format("HH:mm") : value.format("HH:mm");

                                                      const endTime = endTimeValue ? endTimeValue.format("HH:mm") : value.format("HH:mm");

                                                      const onlyTimeForStartTime = moment(startTime, "HH:mm");
                                                      const onlyTimeForEndTime = moment(endTime, "HH:mm");

                                                      if (onlyTimeForStartTime && onlyTimeForEndTime.isSameOrBefore(onlyTimeForStartTime)) {
                                                        throw new Error(t("Start time must be before end time"));
                                                      }

                                                      const allTimes = getFieldValue(["availabilityData", name, "workingHours"])?.map((time) => ({
                                                        startTime: moment(time?.startTime).format("HH:mm"),
                                                        endTime: moment(time?.endTime).format("HH:mm"),
                                                      }));

                                                      if (checkTimeOverlap(onlyTimeForStartTime, onlyTimeForEndTime, allTimes, fieldName)) {
                                                        throw new Error(t("Time ranges cannot overlap"));
                                                      }
                                                    },
                                                  }),
                                                ]}
                                              >
                                                <DatePicker
                                                  showTime={{
                                                    format: "HH:mm",
                                                    use12Hours: false,
                                                    minuteStep: 15,
                                                  }}
                                                  picker="time"
                                                  placeholder={t("End Time")}
                                                  allowClear={false}
                                                  size="small"
                                                  className="w-100"
                                                  format="HH:mm "
                                                  onChange={(value, value1) => {
                                                    const dateString = moment().format("YYYY-MM-DD");
                                                    const dateTimeString = `${dateString}T${value1}:00.000Z`;
                                                    const actualDateTime = moment.utc(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSSZ");

                                                    form.setFieldValue(["availabilityData", name, "workingHours", fieldName, "endTime"], actualDateTime);
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                            {form.getFieldValue(["availabilityData", name, "workingHours"]).length > 1 && (
                                              <Col
                                                span={1}
                                                style={{
                                                  margin: "11px 0 0 10px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <FaMinusCircle
                                                  onClick={() => {
                                                    remove(fieldName);
                                                  }}
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        ))}
                                        <Button type="text" onClick={() => add()}>
                                          <BiPlusCircle style={{ fontSize: "18px" }} />
                                        </Button>
                                      </>
                                    )}
                                  </Form.List>
                                </>
                              )}
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </>
                  )}
                </Form.List>
              </Row>

              <Form.Item>
                <Button loading={isUpdatingDoctorAvailabilityData} type="primary" htmlType="submit">
                  {t("Save")}
                </Button>
              </Form.Item>
            </Form>
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default DoctorAvailability;
