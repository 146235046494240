import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio } from "antd";

import PhoneNumberInput from "components/util-components/Phone/PhoneNumberInput";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { isValidArray, removeFalsyValues, setLocalStorageItems } from "utils/utilities";

export const LoginForm = () => {
  const { loginStore } = useMainStore();
  const navigate = useNavigate();
  const isFirtsLogin = useFirstLogin();

  const [loginWith, setLoginWith] = useState("email");

  const { t } = useTranslation();

  const options = [
    {
      label: t("Doctor"),
      value: "email",
    },
    {
      label: t("Patient"),
      value: "mobile",
    },
  ];

  const onFinish = async (values) => {
    try {
      const { handleUserLogin } = loginStore;
      const { mobile, ...restValues } = values;

      const newBody = removeFalsyValues({
        mobile: mobile ? `+${mobile}` : null,
        ...restValues,
        loginwith: loginWith,
      });

      const {
        success,
        data: { userData },
      } = await handleUserLogin(newBody);

      const [{ user_id, userType, access_token, refresh_token, isDoctor, isPatient, step = 0, first_login = false }] = userData;

      if (success && isValidArray(userData)) {
        setLocalStorageItems({
          setAll: true,
          items: { user_id, userType, access_token, refresh_token, step, first_login },
        });
        if (isDoctor) {
          if (!first_login) return navigate("/doctor/dashboard");
          return isFirtsLogin(first_login, step);
        }
        if (isPatient) return navigate("/patient/dashboard");
        navigate("/");
        return;
      }

      throw new Error("Not valid user");
    } catch (error) {
      navigate("/login");
    }
  };

  return (
    <>
      <Observer>
        {() => {
          const { isUserLogin, setForgotPasswordModal } = loginStore;
          return (
            <>
              <div className="text-center">
                <h3>{t("Login")}</h3>
              </div>
              <div className="w-100">
                <Radio.Group value={loginWith} className="w-100 mb-3 login_option" onChange={(e) => setLoginWith(e.target.value)} options={options} optionType="button" buttonStyle="solid" />
              </div>
              <Form layout="vertical" name="login-form" onFinish={onFinish} validateTrigger="onSubmit">
                {loginWith === "email" && (
                  <div>
                    <Form.Item
                      name="email"
                      label={t("Email")}  
                      rules={[
                        {
                          required: true,
                          message: t("Please input your email"),
                        },
                        {
                          type: "email",
                          message: t("Please enter a valid email!"),
                        },
                      ]}
                    >
                      <Input prefix={<MailOutlined className="text-primary" />} />
                    </Form.Item>
                    {/* <Button type="link" >Login Via Mobile</Button> */}
                  </div>
                )}

                {loginWith === "mobile" && (
                  <Form.Item
                    name="mobile"
                    label={t("Mobile")}
                    rules={[
                      {
                        required: true,
                        message: t("Please enter your mobile"),
                      },
                    ]}
                  >
                    <PhoneNumberInput />
                  </Form.Item>
                )}
                <div className="d-flex flex-column align-items-end">
                  <Form.Item
                    className="m-0 w-100"
                    name="password"
                    label={t("Password")}
                    rules={[
                      {
                        required: true,
                        message: t("Please input your password"),
                      },
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined className="text-primary" />} />
                  </Form.Item>
                  <Button className="m-0 p-0" type="link" onClick={() => setForgotPasswordModal({ show: true, isDoctor: loginWith === "email" })}>
                    {t("Forgot Password")} ?
                  </Button>
                </div>
                <Form.Item>
                  <Button loading={isUserLogin} type="primary" htmlType="submit" block>
                    {t("Login")}
                  </Button>
                </Form.Item>
              </Form>
            </>
          );
        }}
      </Observer>
    </>
  );
};

export default LoginForm;
