export const API_BASE_URL = "https://your-api-url.com/";

//! Common
export const GET_ALL_SPECIALITY = "/common/all/speciality";
export const GET_ALL_DEGREES = "/common/all/degrees";
export const GET_EXPERIENCE_KEY_VALUE = "/common/experience";
export const GET_GENDER_KEY_VALUE = "/common/gender";
export const GET_USER_DETAIL_BY_ID = "/user/userById";
export const UPDATE_PROFILE_IMAGE = "/common/update-profile-image";
export const UPDATE_USER_PASSWORD = "/user/update-password";
export const GET_URL_FOR_UPLOADADE_CERTIFICATE = "/common/upload-certificate";
export const DELETE_DOCTOR_DOCUMENTS = "/common/delete-certificate";
export const GET_NOTIFICATION = "/common/get-notifications";
export const READ_NOTIFICATION = "/common/read-notifications";

//! GET_USER_TYPE
export const GET_DOCTOR_AND_PATIENTS = "/user/doctor-and-patient";

//! SIGN_IN
export const SIGN_IN_USER = "/user/create";
export const SEND_OTP = "/user/create/patient";
export const VERIFY_OTP = "/user/verify-mobile-otp";

//! Login
export const USER_LOGIN = "/user/login";
export const SEND_RESET_PASSWORD_LINK = "/user/forgot-password";
export const RESET_PASSWORD = "/user/reset-password";

// /user/get-user-type/:userTypeId
export const USER_TYPE = "/user/get-user-type";
// /user/check-valid-user-and-type/:userId/:typeId

//! Doctor Specific
// /doctor/details/:doctorId
export const GET_DOCTOR_DETAILS = "/doctor/details";
export const GET_DOCTOR_DETAILS_DOCTOR_ID = "/doctor/details-by-doctor-id";
export const CHECK_VALID_USER_AND_TYPE = "/user/check-valid-user-and-type";
export const ADD_OR_UPDATE_DOCTOR_AVAILABILITY_INFO = "/doctor/add-or-update-doctor-availability";
export const GET_DOCTOR_AVAILABILITY_INFO = "/doctor/availability";
export const ADD_DIAGNOSIS_QUESTION_BY_DOCTOR = "/doctor/add-or-update-diagnosis-questions";
export const GET_DIAGNOSIS_QUESTIONS_BY_DOCTOR = "/doctor/get-diagnosis-questions";
export const GET_APPOINTMENT_LIST_BY_DOCTOR = "/appointment/all-for-doctor";
export const GET_DOCTOR_PAYMENT_HISTORY = "/doctor/payment-history";

// /appointment/mark-as-complete/:appointmentId
export const UPDATE_APPOINTMENT_STATUS_BY_DOCTOR = "/appointment/mark-as-complete";
// /doctor/get-payouts-by-admin/:doctorId
export const GET_DOCTOR_PAYOUT_LIST = "/doctor/get-payouts-by-admin";

// /admin/doctor/get-available-balance/:doctorId

export const GET_DOCTOR_AVAILABLE_BALANCE = `admin/doctor/get-available-balance`;

export const GET_DOCTOR_FEES_STRUCTURE = "/doctor/get-fees-by-doctor";
export const GET_CURRENCIES = "/doctor/currencies";
export const ADD_OR_UPDATE_FEES_STRUCTURE = "/doctor/add-or-update-fees-structure";
//! PATIENT_DASHBOARD_REQUEST
// /patient/details/:patientId
export const GET_PATIENT_DETAILS = "/patient/details";
// /patient/updatePatient/:patientId
export const EDIT_PATIENT_PROFILE = "/patient/updatePatient";

// /doctor/by-speciality
export const GET_DOCTOR_BY_SPECIALITY = "/doctor/by-speciality";

//  /doctor/updateProfile/:doctorId
export const UPDATE_DOCTOR_PROFILE = "/doctor/updateProfile";

// /patient/available-slots/:doctorId
export const GET_DOCTOR_AVAILABLE_SLOTS = "/patient/available-slots";

// /doctor/get-diagnosis-questions/:doctorId
export const GET_DIAGNOSIS_QUESTIONS = "/doctor/get-diagnosis-questions";

// patient/upload-test-reports/:patientId
export const GET_URL_FOR_UPLOADADE_DOCUMENT = "/patient/upload-test-reports";

// /add-test-reports-to-appointment/:patientId/:appointmentId
export const UPLOAD_TEST_REPORTS_BY_PATIENT = "/patient/add-test-reports-to-appointment";

// /patient/all-uploaded-reports/:patientId/:appointmentId
export const GET_UPLOADED_TEST_REPORTS_FOR_PRESCRIPTION = "/patient/all-uploaded-reports";

// /appointment/fees-details/:doctorId
export const GET_APPOINTMNET_FEES_DETAILS = "/appointment/fees-details";

// /appointment/book-appointment/:patientId/:doctorId
export const BOOKING_AN_APPTIMENT = "/appointment/book-appointment";

// /appointment/reschedule-appointment/:patientId/:doctorId/:appointmentId

export const UPDATE_APPOINTMENT_SLOT = "/appointment/reschedule-appointment";

// /appointment/cancel-appointment/:patientId/:appointmentId

export const CANCEL_APPOINTMENT_SLOT_BY_PATIENT = "/appointment/cancel-appointment";

// /appointment/all-for-patients/:patientId
export const GET_APPOINTMENT_LIST = "/appointment/all-for-patients";

// /appointment/payment-status/:appointmentId
export const GET_APPOINTMENT_CONFIRMATION_STATUS = "/appointment/payment-status";

// /appointment/for-patients/:patientId/:appointmentId
export const GET_APPOINTMENT_DETAILS = "/appointment/for-patients";
export const ADD_OR_UPDATE_DOCTOR_CLINIC_INFO = "/doctor/add-or-update-clinic-info";
export const GET_DOCTOR_CLINIC_INFO = "/doctor/all-clinics";

export const ADD_NEW_PRESCRIPTION = "appointment/add-prescription";

export const GET_PRESCRIPTION = "appointment/get-prescription-by-appointment";

export const APPOINTMENT_FOR_DOCTOR = "appointment/for-doctors";

// /appointment/get-all-prescription/:patientId
export const GET_PATIENT_PRESCRIPTION_LIST = "/appointment/get-all-prescription";
// /appointment/get-prescription/:patientId/:prescriptionId
export const GET_PATIENT_PRESCRIPTION_DETAILS = "/appointment/get-prescription";

export const APPOINTMENT_DETAILS = "/appointment";
export const USER_DETAILS_BY_ID = "/user/userById";
export const UPDATE_APPOINTMENT_COMPLETION_STATUS = "appointment/update-status-to-complete";

export const DOCTOR_DASHBOARD_STATISTICS = "doctor/dashboard-statistics";

export const DOCTOR_DASHBOARD_GRAPH_DATA = "doctor/dashboard-graph";

export const DOCTOR_APPOINTMENTS_BY_DATE = "doctor/appointment-by-date";

export const PATIENTS_DASHBOARD_STATISTICS = "patient/dashboard-statistics";

export const PATIENT_DASHBOARD_APPOINT_LIST = "patient/appointments-by-date";

export const PATIENT_RESENT_PRESCRIPTIONS_LIST = "patient/resent-prescriptions";

// lab reports
// /patient/all-lab-reports/:patientId

export const GET_ALL_LAB_REPORTS = "patient/all-lab-reports";
// /patient/upload-lab-reports/:patientId
export const GET_UPDATE_LAB_REPORTS = "patient/upload-lab-reports";
// /patient/delete-lab-report/:reportId/:patientId
export const PATIENT_DELETE_LAB_REPORTS = "patient/delete-lab-report";

// /appointment/get-payment-methods/:doctorId
export const GET_PAYMENT_PARTNERS = `/appointment/get-payment-methods`;

// /get-fees-by-patient/:doctorId/:patientId
export const GET_PAYMENT_FEES_BY_DOCOTR_PATIENT = `/appointment/get-fees-by-patient`;

// /patient/payment-history/:patientId
export const GET_PAYMENT_HISTORY = `patient/payment-history`;

export const CREATE_PAYPAL_ORDER = "paypal-checkout/create-order";
export const CAPTURE_PAYPAL_ORDER = "paypal-checkout/capture-order";

export const UPDATE_APPOINTMENT_CONFIRM_STATUS_ZERO = "/appointment/zero-payment-flow";

export const GET_POLICY_DATA = "admin/settings/get-tnc";

export const VERIFY_USER = "user/verify-user";
