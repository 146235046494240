import { Card, Col, Row } from "antd";
import NavLanguage from "components/layout-components/NavLanguage";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import LoginForm from "../components/LoginForm";

const Login = () => {
  const mainStore = useMainStore();
  const { loginStore } = mainStore;
  const { t } = useTranslation();

  const backgroundStyle = {
    backgroundImage: "url(/img/others/img-17.jpg)",
    backgroundSize: "cover",
    height: "100vh",
  };
  return (
    <Fragment>
      <div style={backgroundStyle}>
        <div className="h-100">
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={7}>
                <Card>
                  <div className="my-4">
                    <Row justify="center">
                      <Col xs={24} sm={24} md={20} lg={20}>
                        <div className="text-right">
                          <NavLanguage />
                        </div>
                        <LoginForm />
                        {/* <p className="text-right ">
                                                <Link to="/forgot-password">
                                                    Forgot Password ?
                                                </Link>
                                            </p> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center">
                    <p>
                      {t("Don't have an account yet?")} <Link to="/sign-in">{t("Sign Up")}</Link>
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="/tnc">{t("Terms and Conditions")}</Link>
                    <span className="mx-2 text-muted">
                      <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <ForgotPasswordForm />
    </Fragment>
  );
};

export default Login;
