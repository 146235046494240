import { LogoutOutlined } from "@ant-design/icons";
import { Button, Grid, Menu } from "antd";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onMobileNavToggle } from "store/slices/themeSlice";
import utils from "utils";
import Icon from "../util-components/Icon";
import IntlMessage from "../util-components/IntlMessage";

const { useBreakpoint } = Grid;

const setLocale = (localeKey, isLocaleOn = true) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      console.log("keyString", keyString);
      keyList.push(keyString);
    }
  }
  console.log("keyList", keyList);
  return keyList;
};

const MenuItem = ({ title, icon, path }) => {
  const dispatch = useDispatch();

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

  const closeMobileNav = () => {
    if (isMobile) {
      dispatch(onMobileNavToggle(false));
    }
  };

  return (
    <>
      {icon && <Icon type={icon} />}
      <span>{setLocale(title)}</span>
      {path && <Link onClick={closeMobileNav} to={path} />}
    </>
  );
};

const getSideNavMenuItem = (navItem) => {
  return navItem.map((nav) => {
    return {
      key: nav.key,
      label: <MenuItem title={nav.title} {...(nav.isGroupTitle ? {} : { path: nav.path, icon: nav.icon })} />,
      ...(nav.isGroupTitle ? { type: "group" } : {}),
      ...(nav.submenu.length > 0 ? { children: getSideNavMenuItem(nav.submenu) } : {}),
    };
  });
};

const getTopNavMenuItem = (navItem) =>
  navItem.map((nav) => {
    return {
      key: nav.key,
      label: <MenuItem title={nav.title} icon={nav.icon} {...(nav.isGroupTitle ? {} : { path: nav.path })} />,
      ...(nav.submenu.length > 0 ? { children: getTopNavMenuItem(nav.submenu) } : {}),
    };
  });

const SideNavContent = (props) => {
  const [hardRefresh, setHardRefresh] = useState(null);
  const { routeInfo, hideGroupTitle } = props;
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const sideNavTheme = useSelector((state) => state.theme.sideNavTheme);
  const mobileNav = useSelector((state) => state.theme.mobileNav);

  const { loginStore, globalStore, resetMainStore } = useMainStore();

  const navCollapsed = useSelector((state) => state.theme.navCollapsed);

  const localStorageKeys = ["user_id", "userType", "access_token", "refresh_token", "step", "first_login"];

  useEffect(() => {
    setHardRefresh(Math.random());
  }, [routeInfo]);

  const handleClick = () => {
    // if (key === "Sign Out") {
    resetMainStore();
    for (let index = 0; index < localStorageKeys.length; index++) {
      const element = localStorageKeys[index];
      localStorage.removeItem(element);
    }
    // localStore.clearAll();
    navigate("/login");
    // }
  };

  const handleRouterKeys = (key) => {
    console.log("🚀 ~ handleRouterKeys ~ key:", key);
    if (!key) {
      if (pathname.includes("view-appointment")) {
        if (pathname.includes("patient")) {
          return "appointments-list";
        } else {
          return "doctor-consults";
        }
      } else if (pathname.includes("setting")) {
        return "doctor-setting";
      } else if (pathname.includes("book-appointment")) {
        return "patient-find-doctor";
      }
    } else {
      return key;
    }
  };

  return (
    <Observer>
      {() => {
        const { userNavigation } = loginStore;
        // const { isLoadingUserData, loggedInUserDetails } = globalStore;
        // const [{ name, profile_pic }] = loggedInUserDetails;

        return (
          <>
            <Menu
              mode="inline"
              theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
              selectedKeys={[handleRouterKeys(routeInfo?.key)]}
              className={hideGroupTitle ? "hide-group-title" : ""}
              items={userNavigation}
            />
            <div className="signout_button">
              <Button onClick={handleClick}>
                <LogoutOutlined className="mb-0" /> <span className={navCollapsed ? "d-none" : "d-inline"}>{t("Sign Out")}</span>
              </Button>
            </div>
          </>
        );
      }}
    </Observer>
  );
};

// const TopNavContent = () => {
//   const topNavColor = useSelector((state) => state.theme.topNavColor);

//   const menuItems = useMemo(() => getTopNavMenuItem(navigationConfig), []);

//   return <Menu mode="horizontal" style={{ backgroundColor: topNavColor }} items={menuItems} />;
// };

const MenuContent = (props) => {
  // return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
  return <SideNavContent {...props} />;
};

export default MenuContent;
