import { CHECK_VALID_USER_AND_TYPE, RESET_PASSWORD, SEND_RESET_PASSWORD_LINK, USER_LOGIN, USER_TYPE, VERIFY_USER } from "constants/ApiConstant";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction } = require("mobx");

class LoginStore {
  userNavigation = [];

  isUserLogin = false;
  userTypeData = [{}];
  isUserAlreadyLoggedIn = false;
  isUserAlreadyLoggedLoading = false;

  loggedInUserType = null;
  isValidUserAndType = false;

  isDoctor = false;
  isPatient = false;
  forgotPasswordModal = {
    show: false,
    isDoctor: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  handleUserLogin = async (userData) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setIsUserLogin(true);

        const { response } = await postRequest({ url: USER_LOGIN, payload: userData });

        const { data, error: userLoginError } = getValidDataFromResponse({ response, isRequired: false, isWarning: true });

        if (!userLoginError && isValidArray(data)) {
          this.setIsUserLogin();
          return resolve({ success: true, data: { userData: data } });
        }
        this.setIsUserLogin();
        return reject({ success: false, data: [] });
      } catch (error) {
        console.log("🚀 ~ file: login.store.js:16 ~ LoginStore ~ returnnewPromise ~ error:", error.message);
        return reject({ success: false, data: [] });
      }
    });
  };

  getUserType = async (userTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({ url: `${USER_TYPE}/${userTypeId}` });
        const { data, error: userTypeError } = getValidDataFromResponse({ response, isRequired: false });

        if (!userTypeError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        console.log("🚀 ~ file: login.store.js:16 ~ LoginStore ~ returnnewPromise ~ error:", error.message);
        return resolve({ success: false, data: [] });
      }
    });
  };

  checkForValidUserAndType = async (userId, typeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({ url: `${CHECK_VALID_USER_AND_TYPE}/${userId}/${typeId}` });
        const { data, error: userTypeError } = getValidDataFromResponse({ response, isRequired: false });

        if (!userTypeError && isValidArray(data)) {
          this.setIsValidUserAndType(true);
          return resolve({ success: true, data: data });
        }

        this.setIsValidUserAndType();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setIsValidUserAndType();
        return resolve({ success: false, data: [] });
      }
    });
  };

  sendResetPasswordLink = async (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await postRequest({ url: SEND_RESET_PASSWORD_LINK, payload: email });
        const { data, error: userTypeError, message } = getValidDataFromResponse({ response });

        if (!userTypeError && isValidArray(data)) {
          return resolve({ success: true, data: data, message });
        }

        return resolve({ success: false, data: [], message });
      } catch (error) {
        return resolve({ success: false, data: [] });
      }
    });
  };

  resetPassword = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await postRequest({ url: RESET_PASSWORD, payload: body });
        const { data, error: userTypeError, message } = getValidDataFromResponse({ response });

        if (!userTypeError) {
          return resolve({ success: true, data: data, message });
        }

        return resolve({ success: false, data: [], message });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  verifyUser = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await postRequest({ url: VERIFY_USER, payload: body });
        const { data, error: userTypeError, message } = getValidDataFromResponse({ response });

        if (!userTypeError) {
          return resolve({ success: true, data: data, message });
        }

        return resolve({ success: false, data: [], message });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setIsUserLogin = (value) => {
    runInAction(() => {
      this.isUserLogin = value;
    });
  };

  setUserTypeData = (data = []) => {
    runInAction(() => {
      this.userTypeData = data;
    });
  };

  setLoggedInUserType = (type = null) => {
    runInAction(() => {
      this.loggedInUserType = type;
    });
  };

  setIsValidUserAndType = (valid = false) => {
    runInAction(() => {
      this.isValidUserAndType = valid;
    });
  };

  setIsDoctor = (value = false) => {
    runInAction(() => {
      this.isDoctor = value;
    });
  };
  setIsPatient = (value = false) => {
    runInAction(() => {
      this.isPatient = value;
    });
  };

  setUserNavigation = (data = []) => {
    runInAction(() => {
      this.userNavigation = data;
    });
  };

  setForgotPasswordModal = (value = false) => {
    runInAction(() => {
      this.forgotPasswordModal = value;
    });
  };
}

export default LoginStore;
