import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Table } from "antd";
import { DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";

const DoctorTransactions = () => {
  const { doctorStore } = useMainStore();

  const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

  useEffect(() => {
    const { getDoctorTransectionsDetails, setIsLoadingForDoctorTransectionsDetails } = doctorStore;
    setIsLoadingForDoctorTransectionsDetails(true);
    getDoctorTransectionsDetails(doctorId)
      .then(({ success, data }) => {
        if (success) setIsLoadingForDoctorTransectionsDetails(false);
      })
      .finally(() => {
        setIsLoadingForDoctorTransectionsDetails(false);
      });
  }, []);

  const columns = [
    {
      title: t("Payment Date"),
      dataIndex: "paidOn",
      key: "paidOn",
      render: (_, { paidOn }, ind) => moment.utc(paidOn).format(DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM),
    },
    {
      title: t("Appointment Fees"),
      dataIndex: "appointment_fees",
      key: "appointment_fees",
      render: (_, { appointment_fees, currency_symbol }, ind) => (
        <p className="text-capitalize mb-0">
          {currency_symbol}
          {appointment_fees}
        </p>
      ),
    },
    {
      title: t("Platform Fees (in %)"),
      dataIndex: "platform_fees",
      key: "platform_fees",
      render: (_, { platform_fees, currency_symbol }, ind) => (
        <p className="text-capitalize mb-0">
          {currency_symbol}
          {platform_fees}
        </p>
      ),
    },
    {
      title: t("Receivable Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount, currency_symbol }, ind) => (
        <p className="text-capitalize mb-0">
          {currency_symbol}
          {amount}
        </p>
      ),
    },
    // {
    //   title: t("Payment Status"),
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, { status }, ind) => (
    //     <Tag color={`${status == "paid" || status == "COMPLETED" ? "#6FCE3F" : status == "pending" ? "#FBBF45" : "#FF6D6D"}`} key={ind}>
    //       {status == "paid" || status == t("COMPLETED") ? t("PAID") : t("PENDING")}
    //     </Tag>
    //   ),
    // },
    {
      title: <Row justify="center">{t("View Appointment")}</Row>,
      dataIndex: "appointment_id",
      key: "appointment_id",
      render: (_, { appointment_id, patient_id }, ind) => (
        <Row justify="center">
          <Button className="tabel_icon" type="primary" ghost>
            <Link
              to="/doctor/view-appointment"
              state={{
                appointmentId: appointment_id,
                patientId: patient_id,
              }}
            >
              <EyeOutlined />
            </Link>
          </Button>
        </Row>
      ),
    },
  ];
  return (
    <Observer>
      {() => {
        const { isLoadingForDoctorTransectionsDetails, doctorTransectionsDetails } = doctorStore;
        return (
          <Spin spinning={isLoadingForDoctorTransectionsDetails && !isValidArray(doctorTransectionsDetails)}>
            <Row>
              <h2> {t("Transactions History")}</h2>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={doctorTransectionsDetails.map((item, ind) => ({
                    ...item,
                    key: ind,
                  }))}
                />
              </Col>
            </Row>
          </Spin>
        );
      }}
    </Observer>
  );
};

export default DoctorTransactions;
