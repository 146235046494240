import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ForgetPasswordForm = () => {
  const { loginStore } = useMainStore();
  const navigate = useNavigate();

  // useEffect(() => {
  //     localStore.clearAll();
  // }, []);

  const { t } = useTranslation();
  const onFinish = async (values) => {
    console.log("value", values);

    // try {
    //     const { handleUserLogin } = loginStore;
    //     const {
    //         success,
    //         data: { userData },
    //     } = await handleUserLogin(values);

    //     const [{ user_id, userType, access_token, refresh_token, isDoctor, isPatient }] = userData;

    //     if (success && isValidArray(userData)) {
    //         setLocalStorageItems({ setAll: true, items: { user_id, userType, access_token, refresh_token } });
    //         if (isDoctor) return navigate("/doctor/dashboard");
    //         if (isPatient) return navigate("/patient/dashboard");
    //         navigate("/");
    //         return;
    //     }

    //     throw new Error("Not valid user");
    // } catch (error) {
    //     navigate("/login");
    // }
  };

  return (
    <>
      <Observer>
        {() => {
          {
            /* const { isUserLogin } = loginStore; */
          }
          return (
            <>
              <div className="text-center">
                <h3>{t("Forgot Password")}</h3>
              </div>
              <Form layout="vertical" name="login-form" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  label={t("Email")}
                  rules={[
                    {
                      required: true,
                      message: t("Please input your email"),
                    },
                    {
                      type: "email",
                      message: t("Please enter a valid email!"),
                    },
                  ]}
                >
                  <Input prefix={<MailOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("Password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please input your password"),
                    },
                  ]}
                >
                  <Input.Password prefix={<LockOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    {t("Login")}
                  </Button>
                </Form.Item>
              </Form>
            </>
          );
        }}
      </Observer>
    </>
  );
};

export default ForgetPasswordForm;
