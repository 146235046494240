import { Button, Col, Form, Input, Row } from "antd";
import Loading from "components/shared-components/Loading";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems } from "utils/utilities";

const ClinicInfo = () => {
  const { doctorProfileStore } = useMainStore();
  const [form] = Form.useForm();
  const isFirstLogin = useFirstLogin();

  const formRules = {
    address: {
      required: true,
      message: t("Address is required."),
    },
    address_url: {
      required: true,
      message: t("Address Link is required."),
    },
    country: {
      required: true,
      message: t("Country is required."),
    },
    state: {
      required: true,
      message: t("State is required."),
    },
    city: {
      required: true,
      message: t("City is required."),
    },
    zip_code: {
      required: true,
      message: t("Zip Code is required."),
    },
  };

  useEffect(() => {
    const { getDoctorClinicInfo, setIsLoadingClinicInfo } = doctorProfileStore;
    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
    getDoctorClinicInfo({ doctorId }).finally(() => setIsLoadingClinicInfo());
  }, []);

  const handleClinicInfoForm = (value) => {
    const { addOrUpdateDoctorClinicInfo, setIsUpdatingClinicInfo } = doctorProfileStore;

    const { country, state, city, zipCode, address, address_url } = value;

    const zip = Number(zipCode);

    const newBody = {
      country,
      state,
      city,
      zipCode: zip,
      address,
      address_url,
    };

    const { user_id: doctorId, first_login } = getLocalStorageItems({
      getAll: true,
      key: ["user_id", "step", "first_login"],
    });
    setIsUpdatingClinicInfo(true);
    addOrUpdateDoctorClinicInfo({ payload: newBody, doctorId }).finally(() => {
      setIsUpdatingClinicInfo();
      isFirstLogin(first_login, 1);
    });
  };

  return (
    <Observer>
      {() => {
        const { isUpdatingClinicInfo, isLoadingClinicInfo, doctorClinicInfo } = doctorProfileStore;
        const [{ address, address_url, country, state, city, zipCode }] = doctorClinicInfo;

        if (isLoadingClinicInfo) {
          return <Loading />;
        }
        return (
          <>
            <h2 className="mb-4">{t("Clinic Information")}</h2>
            <Form
              form={form}
              layout="vertical"
              name="clinicInfoForm"
              onFinish={handleClinicInfoForm}
              initialValues={{
                address,
                address_url,
                country,
                state,
                city,
                zipCode,
              }}
            >
              <Row>
                <Col span={24}>
                  <Row gutter={ROW_GUTTER}>
                    {/* ADDRESS SECTION */}
                    <>
                      <Col xxl={12} xl={12} xs={24}>
                        <Form.Item label={t("Address")} name="address" rules={[formRules.address]}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xxl={12} xl={12} xs={24}>
                        <Form.Item label={t("Address Link")} name="address_url" rules={[formRules.address_url]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} xs={24}>
                        <Form.Item label={t("Country")} name="country" rules={[formRules.country]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} xs={24}>
                        <Form.Item label={t("State")} name="state" rules={[formRules.state]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} xs={24}>
                        <Form.Item label={t("City")} name="city" rules={[formRules.city]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} xs={24}>
                        <Form.Item label={t("Zip Code")} name="zipCode" rules={[formRules.zip_code]}>
                          <Input className="no-spinners" type="number" />
                        </Form.Item>
                      </Col>
                    </>

                    <Button loading={isUpdatingClinicInfo} type="primary" htmlType="submit">
                      {t("Save Change")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Observer>
  );
};

export default ClinicInfo;
