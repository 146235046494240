import { JitsiMeeting } from "@jitsi/react-sdk";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment } from "react";

const JitsiMeet = () => {
  const { videoConferenceStore, globalStore } = useMainStore();

  return (
    <Observer>
      {() => {
        const { roomName, userDetails } = videoConferenceStore;
        const { setJitsiAPI } = globalStore;
        const [{ name }] = userDetails;

        return (
          <Fragment>
            <JitsiMeeting
              domain={process.env.REACT_APP_JITSI_URL}
              roomName={roomName}
              configOverwrite={{
                disableModeratorIndicator: true,

                startWithAudioMuted: false,
                startWithVideoMuted: false,
                startScreenSharing: false,
                enableEmailInStats: false,
                disableDeepLinking: false,

                enableWelcomePage: false,

                // welcomePage: {
                // 	disabled: true,
                // 	customUrl: "https://itechnotion.com/"
                // },

                prejoinConfig: {
                  enabled: false,
                  hideDisplayName: false,
                  hideExtraJoinButtons: ["no-audio", "by-phone"],
                },

                toolbarButtons: ["camera", "desktop", "microphone", "mute-everyone", "mute-video-everyone", "tileview"],
                notifications: [],
                hideConferenceSubject: true,
              }}
              interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                filmStripOnly: false,
              }}
              userInfo={{
                displayName: name,
              }}
              onApiReady={(externalApi) => {
                setJitsiAPI(externalApi);
              }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = "calc(100vh - 120px)";
              }}
            />
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default JitsiMeet;
