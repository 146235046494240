import { EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, List, Row, Tag, Tooltip } from "antd";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { isValidArray } from "utils/utilities";

const PastAppointment = ({ patientId }) => {
  const { patientStore } = useMainStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <Observer>
      {() => {
        const { listOfAppointmentPast, isLoadingForAppointmentList, setIsLoadingDoctorsProfile } = patientStore;

        if (!isLoadingForAppointmentList && !isValidArray(listOfAppointmentPast)) {
          return <Empty />;
        }

        return listOfAppointmentPast.map((data) => {
          return Object.entries(data)
            .slice()
            .reverse()
            .map(([year, appointmentData]) => {
              return (
                <Fragment key={year}>
                  <p className="pl-4 font-weight-bold">{year}</p>
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={false}
                    dataSource={appointmentData}
                    renderItem={(item) => {
                      const {
                        doctor_id,
                        is_canceled,
                        doctorDetails,
                        startTime,
                        paymentStatus,
                        patient_id,
                        appointment_id,
                        appointmentMode,
                        appointmentType,
                        unique_id,
                        paymentMode,
                        is_completed,
                        endTime,
                      } = item;
                      return (
                        <>
                          <List.Item key={item.appointment_id}>
                            <Card className="position-relative">
                              {is_canceled && (
                                <Tag className="position-absolute cancel-tag rounded-0 text-uppercase" color="#FF6B72" size="large">
                                  Cancelled
                                </Tag>
                              )}
                              <Row className={`d-flex justify-content-between ${is_canceled ? "mt-3" : ""}`}>
                                <Col className="appointment_date px-3">
                                  <h1 className="mb-0">{moment.utc(startTime).format("DD")}</h1>
                                  <h5>{moment.utc(startTime).format("MMM")}</h5>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Time")}</h4>
                                  <p className="">{moment.utc(startTime).format("hh:mm A")}</p>
                                </Col>
                                <Col span={2} className="">
                                  <h4 className="mb-1">{t("Name")}</h4>
                                  <p className="no-wrap">{doctorDetails.name}</p>
                                </Col>

                                <Col className="">
                                  <h4 className="mb-1">{t("Appointment Mode")}</h4>
                                  <Tag color={appointmentMode == "online" ? "blue" : "cyan"}>{appointmentMode == "online" ? "Virtual" : "Physical"}</Tag>
                                </Col>
                                {/* <Col className="">
                                  <h4 className="mb-1">{t("Payment Mode")}</h4>
                                  <p className="">{capitalizeFirstLetter(paymentMode)}</p>
                                </Col> */}
                                <Col className="">
                                  <h4 className="mb-1">{t("Payment status")}</h4>
                                  <Tag
                                    color={`${paymentStatus == "paid" || paymentStatus == "COMPLETED" || paymentMode == "offline" ? "#6FCE3F" : paymentStatus == "pending" ? "#FBBF45" : "#FF6D6D"}`}
                                  >
                                    {paymentStatus == "paid" || paymentStatus == "COMPLETED"
                                      ? t("PAID")
                                      : paymentMode == "offline"
                                        ? t("PAY IN CLINIC")
                                        : paymentStatus == "pending"
                                          ? t("PENDING")
                                          : t("FAILED")}
                                  </Tag>
                                </Col>
                                <Col className="d-flex align-items-center">
                                  <Tooltip placement="top" title={t("Book Again")} className="ml-2">
                                    <Button
                                      type="primary"
                                      ghost
                                      onClick={() => {
                                        setIsLoadingDoctorsProfile(true);
                                        navigate("/patient/book-appointment", {
                                          state: {
                                            doctorId: doctor_id,
                                            doctorName: doctorDetails.name,
                                            appointmentType: "ongoing",
                                          },
                                        });
                                      }}
                                    >
                                      <ReloadOutlined />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip className="ml-2" placement="top" title={t("View Details")}>
                                    <Button className="" type="primary">
                                      <Link
                                        to="/patient/view-appointment"
                                        state={{
                                          appointment_id,
                                          patient_id,
                                          name: doctorDetails.name,
                                          type: "past",
                                        }}
                                      >
                                        <EyeOutlined />
                                      </Link>
                                    </Button>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        </>
                      );
                    }}
                  />
                </Fragment>
              );
            });
        });
      }}
    </Observer>
  );
};

export default PastAppointment;
