import { CalendarOutlined, DashboardOutlined, DiffOutlined, MoneyCollectOutlined, ProfileOutlined, SearchOutlined, SettingOutlined, TransactionOutlined, UserOutlined } from "@ant-design/icons";
import { DOCTOR_PREFIX_PATH, PATIENT_PREFIX_PATH } from "configs/AppConfig";

// doctor navigation menu
const doctorDashBoardNavTree = [
  {
    key: "dashboards",
    path: `${DOCTOR_PREFIX_PATH}/overview`,
    // title: "doctor dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    isGroupTitle: true,
    submenu: [
      {
        key: "doctor-dashboard",
        path: `${DOCTOR_PREFIX_PATH}/dashboard`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "doctor-consults",
        path: `${DOCTOR_PREFIX_PATH}/appointments`,
        title: "Appointments",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "doctor-transactions",
        path: `${DOCTOR_PREFIX_PATH}/transactions`,
        title: "Payment History",
        icon: TransactionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "doctor-payouts",
        path: `${DOCTOR_PREFIX_PATH}/payouts`,
        title: "Payouts",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "doctor-setting",
        path: `${DOCTOR_PREFIX_PATH}/setting`,
        title: "Settings",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
// patient navigation menu
const patientDashBoardNavTree = [
  {
    key: "patient-dashboards",
    path: `${PATIENT_PREFIX_PATH}/overview`,
    // title: "patient dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    isGroupTitle: true,
    submenu: [
      {
        key: "patient-dashboard",
        path: `${PATIENT_PREFIX_PATH}/dashboard`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "patient-find-doctor",
        path: `${PATIENT_PREFIX_PATH}/find-doctor`,
        title: "Find Doctor",
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "appointments-list",
        path: `${PATIENT_PREFIX_PATH}/appointments`,
        title: "Appointments",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "prescriptions-list",
        path: `${PATIENT_PREFIX_PATH}/prescription-list`,
        title: "Prescriptions",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "patient-lab",
        path: `${PATIENT_PREFIX_PATH}/lab-reports`,
        title: "Lab Reports",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "patient-transactions",
        path: `${PATIENT_PREFIX_PATH}/transactions`,
        title: "Payment History",
        icon: TransactionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "patient-profile",
        path: `${PATIENT_PREFIX_PATH}/profile`,
        title: "My Profile",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //     key: "patient-paypal",
      //     path: `${PATIENT_PREFIX_PATH}/paypal`,
      //     title: "PayPal",
      //     icon: DiffOutlined,
      //     breadcrumb: false,
      //     submenu: [],
      // },
    ],
  },
];

const navigationConfig = [];
export const doctorNavigationConfig = [...doctorDashBoardNavTree];
export const patientNavigationConfig = [...patientDashBoardNavTree];

export default navigationConfig;
