import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { DoctorSVG, PatientSVG } from "assets/svg/icon";
import PhoneNumberInput from "components/util-components/Phone/PhoneNumberInput";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  const { signInStore } = useMainStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const [UserType, setValueUserType] = useState("doctor");
  const [form] = Form.useForm();

  const [activeUserType, setActiveUserType] = useState(0);

  useEffect(() => {
    const { getUserTypes } = signInStore;
    getUserTypes();
  }, []);

  const rules = {
    user_type: [
      {
        required: true,
        message: t("Please select your type."),
      },
    ],
    email: [
      {
        required: true,
        message: t("Please input your email address."),
      },
      {
        type: "email",
        message: t("Please enter a valid email."),
      },
    ],
    password: [
      {
        required: true,
        message: t("Please input your password."),
      },
    ],
    otp: [
      {
        required: true,
        message: t("Please input your OTP."),
      },
    ],
    mobile: [
      {
        required: true,
        message: t("Please enter your mobile."),
      },
    ],
  };

  const onFinish = async (values) => {
    const { userTypes, createNewUser, sendOTP, OTPField, verifyOTP, setIsCreatingNewUser } = signInStore;
    const { _id } = userTypes.find(({ type }) => type == activeUserType);
    try {
      if (activeUserType === 0) {
        const new_body = {
          ...values,
          type: _id,
        };
        const { success, data } = await createNewUser(new_body);

        if (success) {
          navigate("/login");
        }
      }

      if (activeUserType === 1 && !OTPField) {
        setIsCreatingNewUser(true);

        const { mobile, password } = values;

        const new_body = {
          mobile: `+${mobile}`,
          password,
        };

        const { success, data } = await sendOTP(new_body);

        setIsCreatingNewUser(false);
      } else {
        const { mobile, otp } = values;

        const new_body = {
          mobile: `+${mobile}`,
          otp,
        };

        setIsCreatingNewUser(true);

        const { success, data } = await verifyOTP(new_body);

        setIsCreatingNewUser(false);

        if (success) {
          navigate("/login");
        }
      }
    } catch (error) {
      setIsCreatingNewUser(false);
    }
  };

  return (
    <>
      <Observer>
        {() => {
          const { userTypes, isCreatingNewUser, OTPField } = signInStore;
          return (
            <>
              <div className="text-center">
                <h3>{t("Sign Up ")}</h3>
              </div>
              <Form layout="vertical" form={form} name="register-form" onFinish={onFinish}>
                <Row gutter={[24, 0]} justify="center">
                  <Col sm={10} md={12} xl={8}>
                    <Card
                      className={`${activeUserType === 0 ? "active_card" : ""} cursor_pointer p-0 `}
                      // title="Doctor"
                      // bodyStyle={{width: '100px'}}
                      onClick={() => setActiveUserType(0)}
                    >
                      <p className="mb-1 font-weight-bold">{t("Doctor")}</p>
                      <DoctorSVG />
                    </Card>
                  </Col>
                  <Col sm={10} md={12} xl={8}>
                    <Card
                      className={`${activeUserType === 1 ? "active_card" : ""} cursor_pointer p-0`}
                      // title="Patient"
                      onClick={() => setActiveUserType(1)}
                    >
                      <p className="mb-1 font-weight-bold">{t("Patient")}</p>
                      <PatientSVG />
                    </Card>
                  </Col>
                </Row>
                {activeUserType === 0 && (
                  <Form.Item name="email" label={t("Email")} rules={rules.email} hasFeedback>
                    <Input prefix={<MailOutlined className="text-primary" />} />
                  </Form.Item>
                )}
                {activeUserType === 1 && (
                  <Form.Item name="mobile" label={t("Mobile")} rules={rules.mobile} hasFeedback>
                    <PhoneNumberInput />
                  </Form.Item>
                )}
                <Form.Item name="password" label={t("Password")} rules={rules.password} hasFeedback>
                  <Input.Password disabled={OTPField} prefix={<LockOutlined className="text-primary" />} />
                </Form.Item>

                {activeUserType === 1 && OTPField && (
                  <Form.Item name="otp" label={t("OTP")} rules={rules.otp} hasFeedback>
                    <Input className="otp_field" maxLength={6} />
                  </Form.Item>
                )}
                {activeUserType === 0 && (
                  <Form.Item>
                    <Button loading={isCreatingNewUser} type="primary" htmlType="submit" block>
                      {t("Sign Up")}
                    </Button>
                  </Form.Item>
                )}
                {activeUserType === 1 && !OTPField && (
                  <Form.Item>
                    <Button loading={isCreatingNewUser} type="primary" htmlType="submit" block>
                      {t("Send OTP")}
                    </Button>
                  </Form.Item>
                )}

                {activeUserType === 1 && OTPField && (
                  <Form.Item>
                    <Button loading={isCreatingNewUser} type="primary" htmlType="submit" block>
                      {t("Verify OTP")}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </>
          );
        }}
      </Observer>
    </>
  );
};

export default SignInForm;
