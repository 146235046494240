import { Button, Card, Col, Form, Input, Radio, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { isValidArray } from "utils/utilities";
import UploadLabReports from "../component/UploadLabReports";
import { t } from "i18next";

const StepTwo = ({ commentForm, form }) => {
  const { patientStore } = useMainStore();
  // upload document objects

  const uploadDocumentByOption = (value) => {
    const { setAppointmentSelectDocumentData, appointmentSelectDocumentData } = patientStore;
    setAppointmentSelectDocumentData({
      ...appointmentSelectDocumentData,
      selectDocument: value,
    });
  };
  return (
    <Observer>
      {() => {
        const { isDoctorDiagnosisQuestion, isAppointmentFormData, allLabReports, setIsOpenModalForAddLab, isLoadingForAllLabReports } = patientStore;

        const [{ questions = [] }] = isDoctorDiagnosisQuestion;
        if (isLoadingForAllLabReports) {
          return;
        }

        const [{ reports = [] } = {}] = allLabReports;
        const selectiveQuestions = questions.filter(({ ansType }) => ansType === "boolean");
        const descriptiveQuestions = questions.filter(({ ansType }) => ansType === "descriptive");

        const { date, preDiagnosisQuestions, slotTime, testReports, patientId, patientName } = isAppointmentFormData;

        return (
          <>
            <Row className="mt-3" gutter={[16, 16]}>
              <Col span={18}>
                {(isValidArray(selectiveQuestions) || isValidArray(descriptiveQuestions)) && (
                  <Card className="mt-3 w-100" title={t("Answer some Diagnosis Questions")}>
                    <Form form={form} name="myForm">
                      <Row gutter={[32, 5]} justify="space-between">
                        {descriptiveQuestions?.map(({ question, inputType }, _ind) => {
                          return (
                            <Col span={12} key={_ind}>
                              {inputType == "text" && (
                                <Form.Item
                                  label={question}
                                  labelCol={{ span: 24 }}
                                  name={question}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("This field is required"),
                                    },
                                  ]}
                                >
                                  <Input className="w-100" type={inputType}></Input>
                                </Form.Item>
                              )}
                            </Col>
                          );
                        })}
                        {selectiveQuestions?.map(({ question, inputType }, _ind) => {
                          return (
                            <Col span={12} key={_ind}>
                              {inputType == "radio" && (
                                <span className="d-flex align-items-center step_2_form">
                                  <Form.Item
                                    label={question}
                                    name={question}
                                    labelCol={{ span: 24 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("This field is required"),
                                      },
                                    ]}
                                  >
                                    <Radio.Group>
                                      <Radio value="yes">{t("Yes")}</Radio>
                                      <Radio value="no">{t("No")}</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </span>
                              )}
                            </Col>
                          );
                        })}
                      </Row>
                    </Form>
                  </Card>
                )}
                <Card title={t("Symptoms")}>
                  <Form form={commentForm} name="commentForm">
                    <Form.Item labelCol={{ span: 24 }} label={t("Write symptoms")} name={"comment"}>
                      <TextArea rows={4} placeholder={t("Add symptoms or what you are feeling")} />
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="mt-3 w-100" title={t("Upload Reports (If any)")}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <span className="">{t("Select reports from the list below")}:</span>

                      <Select
                        mode="multiple"
                        className="w-100"
                        placeholder={t("Select Reports")}
                        maxTagCount={2}
                        optionFilterProp="label"
                        defaultValue={[...testReports]}
                        options={reports?.map(({ reportName, report__id, reportDate }) => {
                          return {
                            key: report__id,
                            label: reportName,
                            value: report__id,
                          };
                        })}
                        onChange={uploadDocumentByOption}
                      />
                    </Col>
                    <Col span={24} className="d-flex justify-content-center">
                      <span>{t("or upload new report")}</span>
                    </Col>
                    <Col span={24} className="d-flex justify-content-center">
                      <Button className="w-100" type="primary" ghost onClick={() => setIsOpenModalForAddLab(true)}>
                        {t("Upload Reports")}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <UploadLabReports type="book-appointment" />
          </>
        );
      }}
    </Observer>
  );
};

export default StepTwo;
